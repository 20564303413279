import {
  createSlice,
  createAsyncThunk,
  type PayloadAction,
} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { target, role, nsp, contentKey, isScreenShareViewer } from "config";
import {
  addSequence,
  addStories,
  removeStory,
  setStories,
  updateSequence,
} from "Store/stories";
import { signOut } from "Store/auth/thunks";
import { getPersistedStories } from "Store/storiesOfflineFirstManager";
import type { RootState } from "Store";
import type { Stories, Story, Sequence } from "Store/stories";

const logSlideError = (data: Record<string | number | symbol, unknown>) => {
  if (isScreenShareViewer)
    Sentry.captureMessage(`A presentation is missing slide data`, {
      level: "error",
      extra: { target, role, nsp, contentKey, ...data },
    });
  else
    console.error(`A presentation is missing slide data`, {
      target,
      role,
      nsp,
      contentKey,
      ...data,
    });
};

const slide = (state: SlidesState, { s, sequenceId, x, y }: SlidePosition) => {
  const source = sequenceId
    ? state.entities.find(
        ({ position: p }) =>
          p.s === s && p.sequenceId === sequenceId && p.x === x
      )
    : state.entities.find(
        ({ position: p }) => p.s === s && p.x === x && p.y === y
      );
  const slide = source?.state;
  if (!slide) logSlideError({ s, sequenceId, x, y });
  return slide ?? {};
};

type SlidePositionByIndex = {
  s: number;
  x: number;
  y?: number;
};

export type SlidePosition = SlidePositionByIndex & { sequenceId?: number };

type PagerFormatValue = "arrows" | "buttons-left" | "tabs-bottom" | "tabs-left";

type ObjectFit = "cover" | "contain";

type VideoControlsFormat = "none" | "minimal" | "small" | "large";

type VideoPlaybackMode = "autoplay" | "controls" | "autoplayWithoutLooping";

export type MediaItem = {
  id: number;
  name?: string;
  title: string;
  caption: string;
  objectFit: ObjectFit;
  size: number;
  mimeType: string;
  fileName: string;
  source: string;
  yaw?: number;
  pitch?: number;
  embeds: Array<HotspotItem>;
  type?: "Image" | "Video" | "Pano360" | "Model";
  zoomable?: boolean;
  hotspots: HotspotItem[];
  playbackMode?: VideoPlaybackMode;
  poster?: Omit<MediaItem, "poster"> | string;
  thumbnail?: string;
};

type HotspotItem = {
  modalVariant: "media" | "popover";
  marker: {
    icon: "Plus" | "Photo" | "Pano360";
    position: {
      top: string;
      left: string;
    };
  };
  content: {
    media: { type: "Image" | "Video" | "Pano360"; source: string }[];
  };
};

export type AllSlideTypeKeys =
  | "Image"
  | "Gallery"
  | "MultiDisplayGallery"
  | "Video"
  | "ImageWithEmbeds"
  | "ImageWithHotspots"
  | "GalleryWithHotspots"
  | "View360"
  | "Pano360"
  | "Object3D"
  | "Wireframe"
  | "GroupedGalleries"
  | "TextWithBackground"
  | "ListGallery"
  | BespokeSlideTypeKeys;

type BespokeSlideTypeKeys =
  | "ACRE_FLOORPLANS"
  | "ACRE_SPACEPLANS"
  | "ACRE_TITLESLIDEGALLERY"
  | "BISHOPSGATE8_FLOORPLANS"
  | "BISHOPSGATE8_SPECGRID"
  | "BISHOPSGATE8_VIDEOBACKGROUND"
  | "BISHOPSGATE8_ZOOMABLEGALLERY"
  | "WHITECITYPLACE_ACCORDION"
  | "WHITECITYPLACE_SITEPLAN"
  | "WHITECITYPLACE_AERIAL"
  | "WHITECITYPLACE_FLOORPLANS"
  | "WHITECITYPLACE_SCHEDULE"
  | "WOODCRESCENT_FLOORPLANS"
  | "WOODCRESCENT_SCHEDULE";

export interface Slide {
  title: string;
  titleBar?: string;
  thumbnail?: string;
  isCurrent: boolean;
  position: SlidePositionByIndex;
  presentationId?: number;
  slideId: number;
  slideType: { key: AllSlideTypeKeys };
  slideTypeId: number;
  type: AllSlideTypeKeys;
  speakerNotes?: {
    title?: string;
    body: string;
  };
  swipeableDisabled: boolean;
  setSwipeableDisabled: (disabled: boolean) => void;
  analyticsEventCallback?: unknown;
  screenCapFilename?: string;
  roomControlCommands?: Array<string>;
  typography?: Record<string, unknown>;
  typographyVariant?: string;
  autoPlay?: boolean; // TODO remove this

  //? Object3D
  model: {
    source: string;
    poster: {
      source: string;
    };
  };
  controlsEnabled: boolean;
}

export interface CustomFloorplansSlide extends Slide {
  defaultFloorIndex?: number;
  selectedItemIndex: number;
  state?: {
    selectedLevelIndex?: number;
    transform?: unknown;
  };
}

export interface CustomScheduleSlide extends Slide {
  image: unknown;
  buildingKey: string | number;
}

export interface BishopsgateZoomableGallerySlide extends Slide {
  source: string;
  sourceWidescreen: string;
  areas: Array<{
    caption: string;
    transform: string;
  }>;
}

export interface BishopsgateVideoBackgroundSlide extends Slide {
  media: Array<MediaItem>;
  withFloorGuide: boolean;
  floorGuideSource: string;
  text: string;
  autoHeight: unknown;
  sizeOverride: unknown;
  loop: boolean;
}

export interface WhiteCityPlaceAccordionSlide extends Slide {
  headline: string;
  items: Array<{
    title: string;
    body: string;
    image: {
      source: string;
    };
  }>;
}

export interface WhiteCityPlaceFloorplansSlide extends Slide {
  buildingKey: "east" | "central" | "west";
  state: {
    transform: unknown;
  };
}

export interface WoodCrescentFloorplansSlide extends Slide {
  buildingKey: "1wc";
  state: {
    transform: unknown;
  };
}

export interface TextWithBackgroundSlide extends Slide {
  text: string;
  textAlignment?: "top-left" | "center";
  media: MediaItem;
}

export interface GallerySlide extends Slide {
  media: MediaItem[];
  overviewGrid: boolean;
  pagerFormat: PagerFormatValue;
  gallerySlide: number;
  video: { shouldPlay: boolean };
}

export interface ListGallerySlide extends Slide {
  sections: Array<{ title: string; thumbnail: string }>;
  showModal: boolean;
  media: MediaItem[];
  gallerySlide: number;
  activeGallery?: number | null;
}

export interface MultiDisplayGallerySlide extends Slide {
  displays: MediaItem[];
  gallerySlide: number;
  video: { shouldPlay: boolean; selectedTimecode: number };
}

export interface GalleryWithHotspotsSlide extends Slide {
  images: Array<{
    source: string;
    embeds: Array<HotspotItem>;
  }>;
  imageIndex: number;
  hotspotIndex: number;
  gallery: number;
  gallerySlide: number;
  overviewGrid: boolean;
  showModal: boolean;
  videoShouldPlay: boolean;
  videoSelectedTimecode: number;
  hotspotItemIndex: number;
  pano360ControlsEnabled: boolean;
  selectedTimecode: number;
  pagerFormat: {
    value: string;
  };
}

export interface ImageWithHotspotsSlide extends Slide {
  image: {
    source: string;
    embeds: Array<HotspotItem>;
  };
  gallery: number;
  gallerySlide: number;
  showModal: boolean;
  videoShouldPlay: boolean;
  videoSelectedTimecode: number;
  pano360ControlsEnabled: boolean;
}

export interface VideoSlide extends Slide {
  autoPlay: boolean;
  controlsFormat: VideoControlsFormat;
  fit: ObjectFit;
  loop: boolean;
  media: {
    poster: string;
    source: string;
    sourceIpad?: string; // Unique to 8Bishopsgate
  };
  chapters?: Array<{ text: string; seconds: number }>;
  shouldPlay: boolean;
  selectedTimecode: number;
}

export interface GroupedGalleriesSlide extends Slide {
  galleries: Array<{ title: string; media: Array<MediaItem> }>;
}

export interface Object3DSlide extends Slide {
  model: {
    source: string;
    poster: {
      source: string;
    };
  };
  controlsEnabled: boolean;
}

export interface View360Slide extends Slide {
  media: Array<MediaItem>;
  controlsEnabled: boolean;
}

export interface WireframeSlide extends Slide {
  description?: string;
}

type AllSlideTypes = Slide &
  (
    | GallerySlide
    | ImageWithHotspotsSlide
    | MultiDisplayGallerySlide
    | GalleryWithHotspotsSlide
    | VideoSlide
    | View360Slide
    | WireframeSlide
    | Object3DSlide
  );

export type SlideState = {
  position: SlidePosition;
  typography?: Record<string, unknown>;
  typographyVariant?: string;
  state?: {
    gallery?: number | null;
    gallerySlide?: number | null;
    imageIndex?: number | null;
    hotspotIndex?: number | null;
    hotspotGalleryItemIndex?: number | null;
    controlsEnabled?: boolean;
    showModal?: boolean;
    shouldPlay?: boolean;
    selectedTimecode?: number;
    startMuted?: boolean;
    video?: {
      shouldPlay: boolean;
      selectedTimecode: number;
    };
    pano360?: { controlsEnabled: boolean };
    hawkeye?: {
      visible: boolean;
      corner: "top-left" | "top-right" | "bottom-left" | "bottom-right";
    };
    showSpaceplans?: boolean;
    selectedLevelIndex?: number | null;
    active?: number | null;
    transform?: {
      x?: number | string;
      y?: number | string;
      scale?: number | string;
      willChange?: "auto" | "transform";
    };
    selectedItemIndex?: number | null;
  };
};

export type SlideWithState = AllSlideTypes & SlideState;

type SlidesState = { entities: Array<SlideState> };

const initialState: SlidesState = { entities: [] };

const slidesSlice = createSlice({
  name: "slides",
  initialState,
  reducers: {
    replaceSlides(state, action: PayloadAction<Array<SlideState>>) {
      state.entities = action.payload;
    },
    reset(state) {
      state.entities.forEach((slide) => {
        slide.state = getInitialSlideState(slide);
      });
    },
    setGallery(
      state,
      action: PayloadAction<{ position: SlidePosition; gallery: number | null }>
    ) {
      const { position, gallery } = action.payload;
      slide(state, position).gallery = gallery;
    },
    setImageIndex(
      state,
      action: PayloadAction<{ position: SlidePosition; index: number | null }>
    ) {
      const { position, index } = action.payload;
      slide(state, position).imageIndex = index;
    },
    setHotspotIndex(
      state,
      action: PayloadAction<{ position: SlidePosition; index: number | null }>
    ) {
      const { position, index } = action.payload;
      slide(state, position).hotspotIndex = index;
    },
    setHotspotItemIndex(
      state,
      action: PayloadAction<{ position: SlidePosition; index: number | null }>
    ) {
      const { position, index } = action.payload;
      slide(state, position).hotspotGalleryItemIndex = index;
    },
    setGallerySlide(
      state,
      action: PayloadAction<{
        position: SlidePosition;
        gallerySlide: number | null;
      }>
    ) {
      const { position, gallerySlide } = action.payload;
      slide(state, position).gallerySlide = gallerySlide;
    },
    setShowModal(
      state,
      action: PayloadAction<{ position: SlidePosition; showModal: boolean }>
    ) {
      const { position, showModal } = action.payload;
      slide(state, position).showModal = showModal;
    },
    setKeyValue(
      state,
      action: PayloadAction<{
        position: SlidePosition;
        key:
          | keyof SlideState["state"]
          | "showSpaceplans"
          | "selectedLevelIndex"
          | "active";
        /* eslint-disable @typescript-eslint/no-explicit-any */
        value: any;
      }>
    ) {
      const { position, key, value } = action.payload;
      const s = slide(state, position);
      s[key] = value;
    },
    setVideo(
      state,
      action: PayloadAction<{
        position: SlidePosition;
        startMuted?: boolean;
        shouldPlay?: boolean;
        selectedTimecode?: number;
      }>
    ) {
      const {
        position,
        startMuted = true,
        shouldPlay = null,
        selectedTimecode = null,
      } = action.payload;

      const s = slide(state, position);

      s.startMuted = startMuted;
      if (shouldPlay !== null) s.shouldPlay = shouldPlay;
      if (selectedTimecode !== null) s.selectedTimecode = selectedTimecode;
    },
    setNestedVideo(
      state,
      action: PayloadAction<{
        position: SlidePosition;
        shouldPlay?: boolean;
        selectedTimecode?: number;
      }>
    ) {
      const {
        position,
        shouldPlay = null,
        selectedTimecode = null,
      } = action.payload;
      const s = slide(state, position);
      if (s.video) {
        if (shouldPlay !== null) s.video.shouldPlay = shouldPlay;
        if (selectedTimecode !== null)
          s.video.selectedTimecode = selectedTimecode;
      }
    },
    toggleHawkeye(state, action: PayloadAction<SlidePosition>) {
      const s = slide(state, action.payload);
      if (s.hawkeye) s.hawkeye.visible = !s.hawkeye.visible;
    },
    setPano360ControlsEnabled(
      state,
      action: PayloadAction<{ position: SlidePosition; enabled: boolean }>
    ) {
      const { position, enabled } = action.payload;
      const s = slide(state, position);
      if (s.pano360) s.pano360.controlsEnabled = enabled;
    },
    set3DControlsEnabled(
      state,
      action: PayloadAction<{ position: SlidePosition; enabled: boolean }>
    ) {
      const { position, enabled } = action.payload;
      slide(state, position).controlsEnabled = enabled;
    },
    setMultiDisplayGallerySlide(
      state,
      action: PayloadAction<{
        position: SlidePosition;
        gallerySlide: number | null;
      }>
    ) {
      const { position, gallerySlide } = action.payload;
      slide(state, position).gallerySlide = gallerySlide;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setStories, (state, action) => {
        state.entities = getInitialStateForStories(action.payload);
      })
      .addCase(addStories, (state, action) => {
        let allNewSlides: SlideState[] = [];
        action.payload.forEach((story) => {
          // remove existing stale-state slides
          state.entities = state.entities.filter(
            (slide) => slide.position.s !== story.id
          );
          // add new clean-state slides
          const newSlides = getInitialStateForStory(story);
          allNewSlides.push(...newSlides);
        });

        if (allNewSlides.length > 0) {
          state.entities.push(...allNewSlides);
        }
      })
      .addCase(removeStory, (state, action) => {
        state.entities = state.entities.filter(
          (slide) => slide.position.s !== action.payload.id
        );
      })
      .addCase(addSequence, (state, action) => {
        const { story, sequence } = action.payload;
        // add new clean-state slides
        const newSequenceSlides = getInitialStateForSequence(story, sequence);
        state.entities.push(...newSequenceSlides);
      })
      .addCase(updateSequence, (state, action) => {
        const { story, sequence } = action.payload;
        // remove existing stale-state slides
        state.entities = state.entities.filter(
          (slide) => slide.position.sequenceId !== sequence.id
        );
        // add new clean-state slides
        const newSequenceSlides = getInitialStateForSequence(story, sequence);
        state.entities.push(...newSequenceSlides);
      })
      .addMatcher(
        (action) => [signOut.fulfilled, signOut.rejected].includes(action.type),
        (state, action) => {
          reset();
        }
      );
  },
});

export const fetchInitialOfflineState = createAsyncThunk(
  "slides/fetchInitialOfflineState",
  async (params, { dispatch }) => {
    const initialStoriesOffline = await getPersistedStories();
    const initialSlidesState = getInitialStateForStories(initialStoriesOffline);
    dispatch(replaceSlides(initialSlidesState));
  }
);

function getInitialStateForStories(stories: Stories) {
  return stories?.flatMap((story) => getInitialStateForStory(story));
}

function getInitialStateForStory(story: Story) {
  const presentationSlides = story.columnGroups
    .flatMap(({ columns }) => columns)
    .flatMap(({ slides }, x) =>
      slides.map((slide, y) => {
        const slideState: SlideState = {
          ...slide,
          position: { s: story.id, x, y },
          state: getInitialSlideState(slide),
        };
        if (story.typography) {
          slideState.typography = story.typography[slide.type] as Record<
            string,
            unknown
          >;
        }
        return slideState;
      })
    );

  const sequenceSlides =
    story.sequences?.flatMap((sequence) =>
      getInitialStateForSequence(story, sequence)
    ) ?? [];

  return [...presentationSlides, ...sequenceSlides];
}

function getInitialStateForSequence(story: Story, sequence: Sequence) {
  return sequence.slides.flatMap((slide, x) => {
    const slideState: SlideState = {
      ...slide,
      position: {
        s: story.id,
        sequenceId: sequence.id,
        x,
        y: 0,
      },
      state: getInitialSlideState(slide),
    };
    if (story.typography) {
      slideState.typography = story.typography[slide.type] as Record<
        string,
        unknown
      >;
    }
    return slideState;
  });
}

export function getInitialSlideState(
  slide: Partial<Slide> & SlideState
): SlideState["state"] {
  switch (slide.type) {
    case "Gallery": {
      const s = slide as GallerySlide;
      const hasPano360 = s.media.some((media) => media.type === "Pano360");
      const hasVideo = s.media.some((media) => media.type === "Video");
      const hasZoomable = s.media.some((media) => media.zoomable);
      return {
        gallerySlide: 0,
        hawkeye: {
          visible: false,
          corner: "top-right",
        },
        ...(hasPano360 && {
          pano360: { controlsEnabled: false },
        }),
        ...(hasVideo && {
          video: { shouldPlay: s.autoPlay ?? false, selectedTimecode: 0 },
        }),
        ...(hasZoomable && {
          transform: { x: 0, y: 0, scale: 1 },
        }),
      };
    }

    case "MultiDisplayGallery": {
      const s = slide as MultiDisplayGallerySlide;
      return {
        gallerySlide: 0,
        pano360: { controlsEnabled: false },
        video: { shouldPlay: s.autoPlay ?? false, selectedTimecode: 0 },
      };
    }

    case "TextWithBackground": {
      return {
        hawkeye: {
          visible: false,
          corner: "top-right",
        },
      };
    }

    case "Object3D": {
      return { controlsEnabled: false };
    }

    case "Pano360": {
      return { gallerySlide: 0, controlsEnabled: false };
    }

    case "Video":
    case "BISHOPSGATE8_VIDEOBACKGROUND": {
      return {
        startMuted: true,
        shouldPlay: slide.autoPlay ?? false,
        selectedTimecode: 0,
      };
    }

    case "GalleryWithHotspots": {
      const s = slide as GalleryWithHotspotsSlide;
      const hotspots = s.images.map((image) =>
        image.embeds
          .filter((embed) =>
            Object.prototype.hasOwnProperty.call(embed, "content")
          )
          .flatMap((embed) => embed.content.media)
      );
      const hasVideoHotspots = hotspots.some((hotspot) =>
        hotspot.map((media) => media.type === "Video")
      );
      const hasPano360Hotspots = hotspots.some((hotspot) =>
        hotspot.map((media) => media.type === "Pano360")
      );

      return {
        imageIndex: 0,
        hotspotIndex: 0,
        hotspotGalleryItemIndex: 0,
        showModal: false,
        ...(hasVideoHotspots && {
          video: {
            shouldPlay: s.autoPlay ?? false, // hmm, this can change per embed but value is slide-level
            selectedTimecode: 0,
          },
        }),
        ...(hasPano360Hotspots && {
          pano360: { controlsEnabled: true },
        }),
      };
    }

    case "ImageWithHotspots":
    case "ImageWithEmbeds": {
      const s = slide as ImageWithHotspotsSlide;
      const embeds = s.image.embeds
        .filter((embed) =>
          Object.prototype.hasOwnProperty.call(embed, "content")
        ) // remove markers without content
        .flatMap((embed) => embed.content.media);
      const hasVideoEmbeds = embeds.some((media) => media.type === "Video");
      const hasPano360Embeds = embeds.some((media) => media.type === "Pano360");
      return {
        gallery: null,
        gallerySlide: 0,

        showModal: false,
        ...(hasVideoEmbeds && {
          video: {
            shouldPlay: slide.autoPlay ?? false, // hmm, this can change per embed but value is slide-level
            selectedTimecode: 0,
          },
        }),
        ...(hasPano360Embeds && {
          pano360: { controlsEnabled: true },
        }),
      };
    }

    case "GroupedGalleries":
    case "WHITECITYPLACE_AERIAL": {
      return {
        gallery: null,
        gallerySlide: 0,
        showModal: false,
      };
    }

    case "BISHOPSGATE8_FLOORPLANS":
    case "WHITECITYPLACE_FLOORPLANS": {
      const s = slide as CustomFloorplansSlide;
      return {
        gallery: null,
        gallerySlide: 0,
        showModal: false,
        selectedLevelIndex: s.defaultFloorIndex ?? 0,
        showSpaceplans: false,
        transform: {
          x: 0,
          y: 0,
          scale: 1,
          willChange: "auto",
        },
      };
    }

    case "WOODCRESCENT_FLOORPLANS": {
      const s = slide as CustomFloorplansSlide;
      return {
        gallery: null,
        gallerySlide: 0,
        showModal: false,
        selectedLevelIndex: s.defaultFloorIndex ?? 0,
        showSpaceplans: false,
        transform: {
          x: 0,
          y: 0,
          scale: 1,
          willChange: "auto",
        },
      };
    }

    case "WOODCRESCENT_SCHEDULE": {
      const s = slide as CustomFloorplansSlide;
      return { active: s.defaultFloorIndex ?? 0 };
    }

    case "WHITECITYPLACE_ACCORDION":
    case "WHITECITYPLACE_SITEPLAN":
    case "BISHOPSGATE8_SPECGRID":
    case "BISHOPSGATE8_ZOOMABLEGALLERY": {
      return { active: null };
    }

    case "ACRE_FLOORPLANS":
    case "ACRE_SPACEPLANS": {
      const s = slide as CustomFloorplansSlide;
      return {
        selectedLevelIndex: s.defaultFloorIndex ?? 0,
      };
    }

    case "ACRE_TITLESLIDEGALLERY": {
      const s = slide as CustomFloorplansSlide;
      return {
        selectedItemIndex: s.selectedItemIndex ?? 0,
      };
    }

    default:
      return {};
  }
}

export const {
  replaceSlides,
  setGallery,
  setHotspotIndex,
  setHotspotItemIndex,
  setImageIndex,
  setGallerySlide,
  setShowModal,
  setKeyValue,
  setVideo,
  setNestedVideo,
  toggleHawkeye,
  setPano360ControlsEnabled,
  set3DControlsEnabled,
  setMultiDisplayGallerySlide,
  reset,
} = slidesSlice.actions;

export const selectSlides = (state: RootState) => state.slides.entities;
export const selectSequenceSlides = (state: RootState) => {
  const { sequenceId } = state.structure;
  if (sequenceId === null) return null;
  return state.slides.entities.filter(
    (s) => s.position.sequenceId === sequenceId
  );
};
export const selectSlide = (state: RootState): Partial<Slide> | undefined => {
  const { contentType, storyId, sequenceId, stories } = state.structure;
  if (storyId === null) return;
  const story = stories.find((s) => s.id === storyId);
  if (!story) return;

  let slide = null;
  if (contentType === "Sequence" && sequenceId) {
    const sequence = story.sequences.find(({ id }) => id === sequenceId);
    if (!sequence) return;
    slide = state.slides.entities.find(
      ({ position: p }) =>
        p.sequenceId === sequenceId && p.x === sequence.slideIndex
    );
    if (!slide) logSlideError({ sequenceId, slideIndex: sequence.slideIndex });
  } else {
    const { columns, columnIndex } = story;
    const { slides, slideIndex } = columns[columnIndex];
    if (slides.length === 0) return;
    const { s, x, y } = slides[slideIndex].position;
    slide = state.slides.entities.find(
      ({ position: p }) => s === p.s && x === p.x && y === p.y
    );
    if (!slide) logSlideError(slides[slideIndex].position);
  }

  if (slide) return { ...slide, presentationId: story.id };
};

export default slidesSlice;
