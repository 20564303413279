import React, { useEffect, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TitleBar } from "@showhereco/ui";
import { useOnlineStatus } from "useOnlineStatus";
import { useSyncLocal } from "useSyncLocal";
import { useAppDispatch } from "Store";
import { selectUserIsLoggedIn } from "Store/auth";
import { getUser } from "Store/auth/thunks";
import { fetchInitialOfflineState as fetchInitialOfflineStateStructure } from "Store/structure";
import { fetchInitialOfflineState as fetchInitialOfflineStateSlides } from "Store/slides";
import {
  getStories,
  selectStories,
  selectFirstFetch,
  fetchInitialOfflineState as fetchInitialOfflineStateStories,
} from "Store/stories";
import { selectSlide } from "Store/slides";
import { role, target, isOfflineFirst, dataProvider } from "config";

const Navigation = lazy(() => import("Navigation"));
const SwipeableSlides = lazy(() => import("SlidesRenderer/SwipeableSlides"));
const SlideControls = lazy(() => import("SlidesRenderer/SlideControls"));
const Notifications = lazy(() => import("Notifications"));

export default function Presenter() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (role === "listen") {
      if (target === "local") navigate("/suite-view");
      if (target === "remote") navigate("/remote-join");
    }
    if (role === "static") navigate("/screenshot-slide", { replace: true });
  });

  useSyncLocal();

  useEffect(() => {
    if (role === "broadcast") {
      dataProvider === "api:auth"
        ? dispatch(getUser())
        : dispatch(getStories());
    }
    dispatch(fetchInitialOfflineStateStructure());
    dispatch(fetchInitialOfflineStateStories());
    dispatch(fetchInitialOfflineStateSlides());
  });

  if (role !== "broadcast") return null;

  return (
    <Suspense fallback={<Loading />}>
      <PresenterInner />
    </Suspense>
  );
}

function Loading() {
  return (
    <div className="loading-screen">
      <svg
        width="32"
        height="32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin"
      >
        <path
          opacity=".2"
          d="M16 32a16 16 0 1 1 0-32 16 16 0 0 1 0 32Zm0-28a12 12 0 1 0 0 24 12 12 0 0 0 0-24Z"
          fill="#444"
        />
        <path
          d="M32 16h-4A12 12 0 0 0 16 4V0a16 16 0 0 1 16 16Z"
          fill="#13683E"
        />
      </svg>
    </div>
  );
}

function PresenterInner() {
  const navigate = useNavigate();
  const isOnline = useOnlineStatus();

  const userIsLoggedIn = useSelector(selectUserIsLoggedIn);
  const { error, loading } = useSelector(selectFirstFetch);
  const stories = useSelector(selectStories);
  const slide = useSelector(selectSlide);

  useEffect(() => {
    if (
      dataProvider === "api:auth" &&
      userIsLoggedIn === false &&
      loading === false
    )
      navigate("/auth/sign-in");
  }, [userIsLoggedIn, loading, navigate]);

  if (userIsLoggedIn) {
    return (
      <>
        <SwipeableSlides />
        {slide && <TitleBar text={slide.titleBar ?? slide.title} />}
        <SlideControls />
        <Navigation stories={stories} />
        <Notifications />
      </>
    );
  }

  if (error && !isOfflineFirst) {
    return (
      <div className="loading-error" onClick={() => window.location.reload()}>
        <div className="errors">
          <p className="xxl">Presentations could not be loaded&hellip;</p>
          {isOnline === false && (
            <p>
              You appear to be offline. <br />
              Please connect and try again.
            </p>
          )}
          <pre>{error}</pre>
        </div>
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SwipeableSlides />
      <TitleBar text={slide?.titleBar ?? slide?.title} />
      <SlideControls />
      {stories.length > 0 && <Navigation stories={stories} />}
      <Notifications />
    </>
  );
}
